// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-content {
  display: flex;
  flex-direction: column; /* Asegura que los elementos se apilen en una columna */
  gap: 20px; /* Espacio entre las tarjetas */
}

.card {
  background-color: #85b8fc; /* Fondo blanco */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para el relieve */
  padding: 20px; /* Espacio interno */
  border: 1px solid #ffffff; /* Borde suave */
  display: flex; /* Usa Flexbox dentro de cada tarjeta */
  flex-direction: column; /* Asegura que los elementos dentro de la tarjeta se apilen en una columna */
}

/* Estilos para los párrafos dentro de las tarjetas */
.card p {
  margin: 10px 0; /* Espacio entre párrafos */
  line-height: 1.6; /* Mejora la legibilidad */
}

/* Opcional: Estilo para el título dentro de la tarjeta */
.card h1 {
  margin-bottom: 10px; /* Espacio debajo del título */
}
`, "",{"version":3,"sources":["webpack://./src/pages/HowToStart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB,EAAE,uDAAuD;EAC/E,SAAS,EAAE,+BAA+B;AAC5C;;AAEA;EACE,yBAAyB,EAAE,iBAAiB;EAC5C,mBAAmB,EAAE,uBAAuB;EAC5C,wCAAwC,EAAE,2BAA2B;EACrE,aAAa,EAAE,oBAAoB;EACnC,yBAAyB,EAAE,gBAAgB;EAC3C,aAAa,EAAE,uCAAuC;EACtD,sBAAsB,EAAE,4EAA4E;AACtG;;AAEA,qDAAqD;AACrD;EACE,cAAc,EAAE,2BAA2B;EAC3C,gBAAgB,EAAE,0BAA0B;AAC9C;;AAEA,yDAAyD;AACzD;EACE,mBAAmB,EAAE,8BAA8B;AACrD","sourcesContent":[".about-content {\r\n  display: flex;\r\n  flex-direction: column; /* Asegura que los elementos se apilen en una columna */\r\n  gap: 20px; /* Espacio entre las tarjetas */\r\n}\r\n\r\n.card {\r\n  background-color: #85b8fc; /* Fondo blanco */\r\n  border-radius: 10px; /* Bordes redondeados */\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para el relieve */\r\n  padding: 20px; /* Espacio interno */\r\n  border: 1px solid #ffffff; /* Borde suave */\r\n  display: flex; /* Usa Flexbox dentro de cada tarjeta */\r\n  flex-direction: column; /* Asegura que los elementos dentro de la tarjeta se apilen en una columna */\r\n}\r\n\r\n/* Estilos para los párrafos dentro de las tarjetas */\r\n.card p {\r\n  margin: 10px 0; /* Espacio entre párrafos */\r\n  line-height: 1.6; /* Mejora la legibilidad */\r\n}\r\n\r\n/* Opcional: Estilo para el título dentro de la tarjeta */\r\n.card h1 {\r\n  margin-bottom: 10px; /* Espacio debajo del título */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
