import React from "react";
import Container from "../components/container";
import "./About.css"; // Asegúrate de crear este archivo para los estilos

const About = () => {
  return (
    <Container>
      <div className="about-content">
        <div className="card">
          <h1>
            Bereiten Sie sich mit Hilfe von Künstlicher Intelligenz auf Ihre
            Technologie-Zertifizierung vor
          </h1>
        </div>

        <div className="card">
          <h2></h2>
          <p>
            In einer Welt, in der sich die Technologien rasant weiterentwickeln,
            kann eine Zertifizierung in Plattformen wie <strong>AWS</strong>,{" "}
            <strong>Azure</strong>, <strong>Linux</strong> oder in
            Schlüsselbereichen wie <strong>Scrum Master Professional</strong>{" "}
            den Unterschied zwischen Erfolg und Mittelmäßigkeit in Ihrer
            Karriere ausmachen. Diese Zertifizierungen sind weltweit anerkannt,
            eröffnen neue berufliche Chancen und sind ein Zeichen für
            Fachkenntnis und Wissen. Der Weg, diese zu erreichen, kann jedoch
            herausfordernd, überwältigend und voller Unsicherheiten sein. Hier
            kommt unser Unternehmen ins Spiel, das Ihnen{" "}
            <strong>
              maßgeschneiderte Unterstützung mit Künstlicher Intelligenz (KI)
            </strong>{" "}
            bietet, um Ihre Erfolgschancen zu maximieren.
          </p>
        </div>

        <div className="card">
          <h2>Was bieten wir an?</h2>
          <p>
            Wir wissen, dass das Lernen für Zertifizierungen in der
            Technologiebranche eine anspruchsvolle Aufgabe sein kann,
            insbesondere wenn Sie versuchen, Arbeit, Studium und persönliche
            Verpflichtungen unter einen Hut zu bringen. Daher ist unsere
            KI-basierte Plattform darauf ausgelegt, Ihren Lernprozess zu
            optimieren und Sie Schritt für Schritt zur gewünschten
            Zertifizierung zu führen.
          </p>
        </div>

        <div className="card">
          <h2>KI-gestützte Unterstützung: Ein Effizienter Lernplan</h2>
          <p>
            Unser KI-System bewertet Ihr aktuelles Wissen und erstellt einen
            effizienten und personalisierten Lernplan, der sich Ihrem Lerntempo
            anpasst. Wenn Ihnen ein Thema besonders schwerfällt, wie Netzwerke
            in AWS oder agile Prinzipien für Scrum, passt unsere Plattform die
            Inhalte an, um diese Bereiche zu verstärken. Sie bietet detaillierte
            Erklärungen, praktische Beispiele und Simulationstests, damit Sie
            unter realen Prüfungsbedingungen üben können.
          </p>
        </div>
        <div className="card">
          <h2>KI-gestützte Unterstützung: Ein Effizienter Lernplan</h2>
          <p>
            Unser KI-System bewertet Ihr aktuelles Wissen und erstellt einen
            effizienten und personalisierten Lernplan, der sich Ihrem Lerntempo
            anpasst. Wenn Ihnen ein Thema besonders schwerfällt, wie Netzwerke
            in AWS oder agile Prinzipien für Scrum, passt unsere Plattform die
            Inhalte an, um diese Bereiche zu verstärken. Sie bietet detaillierte
            Erklärungen, praktische Beispiele und Simulationstests, damit Sie
            unter realen Prüfungsbedingungen üben können.{" "}
            <strong>
              Das Lernen mit unserer Künstlichen Intelligenz ist wie ein
              erfahrener Tutor, der Ihnen rund um die Uhr zur Verfügung steht
            </strong>
            .
          </p>
        </div>

        <div className="card">
          <h2>Lernleitfäden für Wichtige Werkzeuge und Software</h2>
          <p>
            Neben der Vorbereitung auf Zertifizierungsprüfungen bieten wir auch{" "}
            <strong>
              detaillierte Lernleitfäden für wesentliche Werkzeuge
            </strong>{" "}
            und Software im heutigen Technologiebereich an. Von der
            Infrastrukturverwaltung mit <strong>Terraform</strong> und{" "}
            <strong>Ansible</strong> bis zur Teamkoordination mit{" "}
            <strong>Slack</strong> und dem Webserver <strong>Nginx</strong>.
            Unsere Materialien decken sowohl die Grundlagen als auch
            fortgeschrittene Aspekte ab, sodass Sie Werkzeuge meistern können,
            die für jeden Fachmann im Technologiebereich unerlässlich sind.
          </p>
        </div>

        <div className="card">
          <h2>Vorteile der Künstlichen Intelligenz für Ihre Vorbereitung</h2>
          <p>
            <strong>1. Personalisierte Lernansätze:</strong> Nicht jeder lernt
            auf dieselbe Weise oder mit der gleichen Geschwindigkeit. Unsere KI
            analysiert Ihre Fortschritte und stellt Ihnen Materialien bereit,
            die an Ihren Lernstil und Ihr Tempo angepasst sind und sich auf
            Bereiche konzentrieren, die mehr Aufmerksamkeit erfordern.
          </p>

          <p>
            <strong>2. Prüfungssimulationen:</strong> Testen Sie Ihr Wissen mit
            realitätsnahen Prüfungssimulationen, zeitlich begrenzt und mit
            Fragen, die dem Schwierigkeitsgrad der offiziellen Prüfungen
            entsprechen. Dies hilft Ihnen nicht nur, sich mit dem Format
            vertraut zu machen, sondern auch, Vertrauen für den Prüfungstag zu
            gewinnen.
          </p>

          <p>
            <strong>3. Zugang zu aktuellen Ressourcen:</strong> Zertifizierungen
            und Technologien entwickeln sich ständig weiter. Unsere Plattform
            bleibt mit den neuesten Änderungen im Prüfungsinhalt und den
            Technologien auf dem Laufenden, damit Sie immer einen Schritt voraus
            sind.
          </p>

          <p>
            <strong>4. Ergänzende Materialien:</strong> Neben den
            Prüfungsinhalten bieten wir <strong>zusätzliches Material</strong>{" "}
            wie Artikel, Videos und Fallstudien, die sich mit wichtigen
            Konzepten vertiefend beschäftigen.
          </p>
        </div>

        <div className="card">
          <h2>
            Positionieren Sie Ihre Karriere mit den Gefragtesten
            Zertifizierungen
          </h2>
          <p>
            Eine Zertifizierung in Technologien wie AWS, Azure oder Linux zu
            erhalten, vermittelt nicht nur die technischen Fähigkeiten, die der
            Markt verlangt, sondern positioniert Sie auch als Fachkraft in Ihrem
            Bereich. Unternehmen schätzen Fachleute, die Engagement für
            Exzellenz und kontinuierliche Verbesserung zeigen. Auf einem
            wettbewerbsintensiven Arbeitsmarkt ist es ein großer Vorteil,
            Zertifizierungen von großen Technologiefirmen vorzuweisen.
          </p>

          <p>
            Mit unserer <strong>KI-gestützten Unterstützung</strong> bieten wir
            Ihnen eine effiziente, personalisierte und moderne Art der
            Vorbereitung auf diese Prüfungen. Sparen Sie Zeit, reduzieren Sie
            Stress und maximieren Sie Ihre Chancen, beim ersten Versuch zu
            bestehen. Egal, ob Sie Anfänger sind oder bereits Erfahrung haben,
            unsere Lernpläne passen sich Ihnen an.
          </p>
        </div>

        <div className="card">
          <h2>Starten Sie Noch Heute</h2>
          <p>
            Wenn Sie den nächsten Schritt in Ihrer technologischen Karriere
            gehen und Zertifizierungen erlangen möchten, die Ihre Kenntnisse
            bestätigen, warten Sie nicht länger. Unsere KI-Plattform ist darauf
            ausgelegt, Ihnen zu <strong>helfen, Ihre Ziele zu erreichen</strong>
            . Ob Sie sich auf eine Zertifizierung in <strong>AWS</strong>,{" "}
            <strong>Azure</strong>, <strong>Scrum</strong> vorbereiten oder
            Werkzeuge wie <strong>Docker</strong>, <strong>Terraform</strong>{" "}
            oder <strong>Slack</strong> meistern möchten, wir unterstützen Sie
            auf jedem Schritt des Weges. Es ist Ihre Zeit, sich
            weiterzuentwickeln und hervorzustechen!
          </p>

          <p>
            Nutzen Sie die Möglichkeiten der Künstlichen Intelligenz und heben
            Sie Ihre Vorbereitung auf das nächste Level.{" "}
            <strong>
              Ihr Erfolg bei den Zertifizierungsprüfungen beginnt hier
            </strong>
            .
          </p>
        </div>

        {/* Añade más tarjetas de la misma forma para los demás títulos y párrafos */}
      </div>
    </Container>
  );
};

export default About;
