// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-content {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Espacio entre las tarjetas */
}

.card {
  background-color: #fff; /* Fondo blanco */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para el relieve */
  padding: 20px; /* Espacio interno */
  border: 1px solid #ddd; /* Borde suave */
}

.card h2 {
  margin-bottom: 10px; /* Espacio debajo del título */
  font-size: 1.5em; /* Tamaño del texto, ajusta según tu preferencia */
  color: #333; /* Color del texto, ajusta según tu diseño */
}
`, "",{"version":3,"sources":["webpack://./src/pages/About.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS,EAAE,+BAA+B;AAC5C;;AAEA;EACE,sBAAsB,EAAE,iBAAiB;EACzC,mBAAmB,EAAE,uBAAuB;EAC5C,wCAAwC,EAAE,2BAA2B;EACrE,aAAa,EAAE,oBAAoB;EACnC,sBAAsB,EAAE,gBAAgB;AAC1C;;AAEA;EACE,mBAAmB,EAAE,8BAA8B;EACnD,gBAAgB,EAAE,kDAAkD;EACpE,WAAW,EAAE,4CAA4C;AAC3D","sourcesContent":[".about-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px; /* Espacio entre las tarjetas */\r\n}\r\n\r\n.card {\r\n  background-color: #fff; /* Fondo blanco */\r\n  border-radius: 10px; /* Bordes redondeados */\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para el relieve */\r\n  padding: 20px; /* Espacio interno */\r\n  border: 1px solid #ddd; /* Borde suave */\r\n}\r\n\r\n.card h2 {\r\n  margin-bottom: 10px; /* Espacio debajo del título */\r\n  font-size: 1.5em; /* Tamaño del texto, ajusta según tu preferencia */\r\n  color: #333; /* Color del texto, ajusta según tu diseño */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
