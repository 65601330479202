// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bot-telegram {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3px 10px; /* Espacio alrededor del texto */
  margin: 3px;
}

buttones {
  background-color: #808080; /* Gris */
  color: white;
  padding: 3px 10px; /* Espacio alrededor del texto */
  margin: 3px;
  border: none;
  border-radius: 5px; /* Bordes redondeados */
  font-size: 16px;
  cursor: pointer;
  display: inline-block; /* Se adapta al tamaño del texto */
}

buttonT:hover {
  background-color: cornflowerblue;
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/azureTelegram.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB,EAAE,gCAAgC;EACnD,WAAW;AACb;;AAEA;EACE,yBAAyB,EAAE,SAAS;EACpC,YAAY;EACZ,iBAAiB,EAAE,gCAAgC;EACnD,WAAW;EACX,YAAY;EACZ,kBAAkB,EAAE,uBAAuB;EAC3C,eAAe;EACf,eAAe;EACf,qBAAqB,EAAE,kCAAkC;AAC3D;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".bot-telegram {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  padding: 3px 10px; /* Espacio alrededor del texto */\r\n  margin: 3px;\r\n}\r\n\r\nbuttones {\r\n  background-color: #808080; /* Gris */\r\n  color: white;\r\n  padding: 3px 10px; /* Espacio alrededor del texto */\r\n  margin: 3px;\r\n  border: none;\r\n  border-radius: 5px; /* Bordes redondeados */\r\n  font-size: 16px;\r\n  cursor: pointer;\r\n  display: inline-block; /* Se adapta al tamaño del texto */\r\n}\r\n\r\nbuttonT:hover {\r\n  background-color: cornflowerblue;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
