// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-container {
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  padding-top: 2%;
  box-sizing: border-box;
  min-height: 100vh;
  width: 95%;
  margin: 0 auto;
  transition: background-color 0.3s ease; /* Efecto de transición */
  background-color: rgb(
    40,
    40,
    233
  ); /* Azul oscuro como color de fondo inicial */
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .centered-container {
    width: 95%;
    padding: 5%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Container.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,iBAAiB;EACjB,UAAU;EACV,cAAc;EACd,sCAAsC,EAAE,yBAAyB;EACjE;;;;GAIC,EAAE,4CAA4C;AACjD;;AAEA,0CAA0C;AAC1C;EACE;IACE,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".centered-container {\r\n  text-align: justify;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: flex-start;\r\n  padding: 10px;\r\n  padding-top: 2%;\r\n  box-sizing: border-box;\r\n  min-height: 100vh;\r\n  width: 95%;\r\n  margin: 0 auto;\r\n  transition: background-color 0.3s ease; /* Efecto de transición */\r\n  background-color: rgb(\r\n    40,\r\n    40,\r\n    233\r\n  ); /* Azul oscuro como color de fondo inicial */\r\n}\r\n\r\n/* Media query para dispositivos móviles */\r\n@media (max-width: 768px) {\r\n  .centered-container {\r\n    width: 95%;\r\n    padding: 5%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
